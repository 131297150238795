<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="gameApi.isError" :api="gameApi" />
        <ApiErrorDialog v-if="PlayerApi.isError" :api="PlayerApi" />
        <ApiErrorDialog v-if="bookApi.isError" :api="bookApi" />
        <ApiErrorDialog
          v-if="SeriesSponsorApi.isError"
          :api="SeriesSponsorApi"
        />
        <ApiErrorDialog v-if="EventSponsorApi.isError" :api="EventSponsorApi" />
        <!-- EOC -->
        <v-container
          class="app-body d-flex flex-column justify-center align-center"
          v-if="selectYearScreen"
        >
          <div class="text-h4 text-center brown--text">
            {{ $t("string.select_year") }}
          </div>
          <div class="text-center">
            <v-select
              :items="lables"
              item-text="name"
              item-value="id"
              v-model="selectedYear"
              background-color="transparent"
              item-color="brown"
              flat
              dense
              hide-details
              solo
              class="ma-3 custom-select"
              color="brown"
            ></v-select>
            <v-btn @click="selectYear()" width="100">
              {{ $t("action.go") }}
            </v-btn>
          </div>
        </v-container>
        <v-container class="app-body text-center" v-else-if="sponsorScreen">
          <div class="text-h4 text-center brown--text mb-4">
            {{ $t("string.sponsors") }}
          </div>
          <!-- <div
            class="d-flex flex-column flex-wrap align-center justify-center my-3"
            v-if="seriesSponsors.length > 0"
          >
            <div
              v-for="(sponsor, j) in seriesSponsors"
              :key="j"
              class="d-flex flex-column align-center mx-3 topic"
              style="max-width: 150px"
            >
              <v-img
                v-if="sponsor.Sponsor.logoUrl"
                :src="sponsor.Sponsor.logoUrl"
                max-width="80"
              >
              </v-img>
              <div
                class="text-center brown--text text--darken-2 mt-2"
                style="line-height: 20px"
              >
                {{ sponsor.Sponsor.name }}
              </div>
            </div>
          </div> -->
          <Sponsors v-if="eventSponsors.length > 0" :items="eventSponsors" />
          <div class="d-flex">
            <v-spacer />
            <v-progress-linear
              color="brown darken-1"
              indeterminate
              height="10"
              style="max-width: 200px"
              class="my-5"
            ></v-progress-linear>
            <v-spacer />
          </div>
        </v-container>
        <v-container class="app-body pt-2" v-else-if="bookScreen">
          <v-card class="bg-white py-4 mx-2 elevation-4">
            <div class="px-5">
              <v-img
                v-if="book.translatableImage"
                lazy-src="https://placehold.co/100?text=hamochi"
                :src="
                  JSON.parse(book.translatableImage)[$_getLocale()]
                    ? JSON.parse(book.translatableImage)[$_getLocale()].url
                    : JSON.parse(book.translatableImage)[subjectLang].url
                "
                class="my-4 mx-auto"
              >
                <template v-slot:placeholder>
                  <div
                    style="
                      background-color: #ccaf8b;
                      width: 100%;
                      padding-top: 100%;
                    "
                  ></div>
                </template>
              </v-img>
              <div
                class="my-4 mx-auto"
                v-else
                style="
                  background-color: #ccaf8b;
                  width: 100%;
                  padding-top: 100%;
                "
              ></div>
              <div
                class="text-center text-h4 my-4"
                v-if="book.translatableName"
              >
                {{
                  JSON.parse(book.translatableName)[$_getLocale()]
                    ? JSON.parse(book.translatableName)[$_getLocale()]
                    : JSON.parse(book.translatableName)[subjectLang]
                }}
              </div>
              <div class="text-center my-4">
                {{ $t("string.authorPre") }} {{ book.author }}
                {{ $t("string.authorPost") }}
              </div>
              <div class="text-center">
                <v-btn
                  max-width="350"
                  width="100%"
                  color="green"
                  dark
                  large
                  class="text-h6"
                  @click="countDown = 0"
                >
                  {{ $t("string.startReading") }}
                  <span style="position: absolute; right: 0">{{
                    countDown
                  }}</span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-container>
        <v-container class="app-body" v-else>
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.hamochi_cup") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <Loader :isLoading="api.isLoading" />
          <Empty
            :condition="
              events.ongoing && events.ongoing.length < 1 &&
              events.latest && events.latest.length < 1 &&
              events.past && events.past.length < 1 &&
              api.isLoading == false
            "
          />
          <div v-if="!api.isLoading">
            <div
              class="text-h5 text-center mb-3 mt-6"
              v-if="events.ongoing && events.ongoing.length > 0"
            >
            {{ $t("string.eventOngoing") }}
            </div>
            <div
              v-for="ongoingEvent in events.ongoing"
              :key="ongoingEvent.code"
              class="game-card elevation-3 pa-2"
            >
              <div class="d-flex mb-2 align-start">
                <div class="text-h6 brown--text text-left flex-grow-1">
                  {{ ongoingEvent.name }}
                  <div class="brown--text text-caption">
                    {{ ongoingEvent.Series.name }}
                  </div>
                </div>
                <div>
                  <div class="d-flex align-center bg-white px-1">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      :src="require('@/assets/explore/menu/ticket.png')"
                    ></v-img>
                    <div class="ml-2">
                      <span v-if="ongoingEvent.type == 'book'">∞</span>
                      <span v-else>{{ ticketCount }}</span>
                    </div>
                  </div>
                  <v-chip x-small dark color="brown">
                    {{ ongoingEvent.subjectName }}
                  </v-chip>
                </div>
              </div>
              <div class="d-flex align-end mb-2">
                <div>
                  <v-img contain width="150" :src="ongoingEvent.imageUrl">
                    <template v-slot:placeholder>
                      <div class="loader"></div>
                    </template>
                  </v-img>
                </div>
                <div class="ml-2" style="flex-grow: 1">
                  <div class="mb-2">
                    <div>
                      <div class="mb-2">
                        <div class="text-h2">
                          {{
                            $moment(ongoingEvent.timestampEnd).diff(
                              $moment(),
                              "days"
                            )
                          }}
                        </div>
                        <div>
                          {{ $t("string.days_left") }}
                        </div>
                      </div>
                      <div>
                        <v-btn
                          large
                          block
                          color="yellow text-h5"
                          @click="
                            selected = ongoingEvent;
                            goToLevels(ongoingEvent.code);
                          "
                          class="animate-shake"
                          id="StartNow"
                          >{{ $t("string.start_now") }}</v-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="brown--text text-caption">
                {{
                  $moment(ongoingEvent.timestampStart).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
                ~
                {{
                  $moment(ongoingEvent.timestampEnd).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
              </div>
            </div>

            <div
              class="text-h5 text-center mt-12 mb-3"
              v-if="events.latest && events.latest.length > 0"
            >
            {{ $t("string.eventLatest") }}
            </div>
            <div
              v-for="latestEvent in events.latest"
              :key="latestEvent.code"
              class="game-card elevation-3 pa-2"
            >
              <div class="d-flex align-start mb-2">
                <div class="text-h6 brown--text text-left flex-grow-1">
                  {{ latestEvent.name }}
                  <div class="brown--text text-caption">
                    {{ latestEvent.Series.name }}
                  </div>
                </div>
                <div>
                  <v-chip x-small dark color="brown">
                    {{ latestEvent.subjectName }}
                  </v-chip>
                </div>
              </div>
              <div class="d-flex align-end mb-2">
                <div>
                  <v-img contain width="150" :src="latestEvent.imageUrl">
                    <template v-slot:placeholder>
                      <div class="loader"></div>
                    </template>
                  </v-img>
                </div>
                <div class="ml-2" style="flex-grow: 1">
                  <div class="mb-2">
                    <div>
                      <div class="text-h5 mb-2">
                        {{ $t("string.event_over") }}
                      </div>
                      <div>
                        <v-btn
                          large
                          block
                          color="yellow"
                          @click="
                            selected = latestEvent;
                            latestEvent.type == 'book'
                              ? goToInfo(latestEvent.code)
                              : goToRank(latestEvent.code);
                          "
                          >{{ $t("string.view_result") }}</v-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="brown--text text-caption">
                {{
                  $moment(latestEvent.timestampStart).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
                ~
                {{
                  $moment(latestEvent.timestampEnd).format("DD/MM/YYYY HH:mm A")
                }}
              </div>
            </div>

            <div
              class="text-h5 text-center mb-3 mt-12"
              v-if="events.comingSoon && events.comingSoon.length > 0"
            >
            {{ $t("string.eventCommingsoon") }}
            </div>
            <div
              v-for="comingSoonEvent in events.comingSoon"
              :key="comingSoonEvent.code"
              class="game-card elevation-3 pa-2"
            >
              <div class="d-flex mb-2 align-start">
                <div class="text-h6 brown--text text-left flex-grow-1">
                  {{ comingSoonEvent.name }}
                  <div class="brown--text text-caption">
                    {{ comingSoonEvent.Series.name }}
                  </div>
                </div>
                <div>
                  <v-chip x-small dark color="brown">
                    {{ comingSoonEvent.subjectName }}
                  </v-chip>
                </div>
              </div>
              <div class="d-flex align-end mb-2">
                <div>
                  <v-img contain width="150" :src="comingSoonEvent.imageUrl">
                    <template v-slot:placeholder>
                      <div class="loader"></div>
                    </template>
                  </v-img>
                </div>
                <div class="ml-2" style="flex-grow: 1">
                  <div class="mb-2">
                    <div>
                      <div class="mb-2">
                        <div class="text-h2">
                          {{
                            $moment(comingSoonEvent.timestampStart).diff(
                              $moment(),
                              "days"
                            )
                          }}
                        </div>
                        <div>
                          {{ $t("string.days_start") }}
                        </div>
                      </div>
                      <div>
                        <v-btn
                          large
                          block
                          color="yellow"
                          @click="goToInfo(comingSoonEvent.code)"
                          >{{ $t("string.read_rules") }}</v-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="brown--text text-caption">
                {{
                  $moment(comingSoonEvent.timestampStart).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
                ~
                {{
                  $moment(comingSoonEvent.timestampEnd).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
              </div>
            </div>

            <div
              class="text-h5 text-center mb-3 mt-12"
              v-if="events.upcoming && events.upcoming.length > 0"
            >
            {{ $t("string.eventUpcoming") }}
            </div>
            <div
              v-for="upcomingEvent in events.upcoming"
              :key="upcomingEvent.code"
              class="game-card elevation-3 pa-2"
            >
              <div class="d-flex mb-2 align-start">
                <div class="text-h6 brown--text text-left flex-grow-1">
                  {{ upcomingEvent.name }}
                  <div class="brown--text text-caption">
                    {{ upcomingEvent.Series.name }}
                  </div>
                </div>
                <div>
                  <v-chip x-small dark color="brown">
                    {{ upcomingEvent.subjectName }}
                  </v-chip>
                </div>
              </div>
              <div class="d-flex align-center mb-2">
                <div>
                  <v-img contain width="150" :src="upcomingEvent.imageUrl">
                    <template v-slot:placeholder>
                      <div class="loader"></div>
                    </template>
                  </v-img>
                </div>
                <div class="ml-2" style="flex-grow: 1">
                  <div class="mb-2">
                    <div>
                      <div class="text-h5">
                        {{ $t("string.coming_on") }}
                      </div>
                      <div class="text-h5">
                        {{
                          $moment(upcomingEvent.timestampStart).format("DD/MM/YYYY")
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="brown--text text-caption">
                {{
                  $moment(upcomingEvent.timestampStart).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
                ~
                {{
                  $moment(upcomingEvent.timestampEnd).format(
                    "DD/MM/YYYY HH:mm A"
                  )
                }}
              </div>
            </div>

            <div
              class="text-h5 text-center mt-12 mb-3"
              v-if="events.past && events.past.length > 0"
            >
            {{ $t("string.eventPast") }}
            </div>
            <div
              v-for="pastEvent in events.past"
              :key="pastEvent.code"
              class="game-card elevation-3 pa-2"
            >
              <div class="d-flex align-start mb-2">
                <div class="text-h6 brown--text text-left flex-grow-1">
                  {{ pastEvent.name }}
                  <div class="brown--text text-caption">
                    {{ pastEvent.Series.name }}
                  </div>
                </div>
                <div>
                  <v-chip x-small dark color="brown">
                    {{ pastEvent.subjectName }}
                  </v-chip>
                </div>
              </div>
              <div class="d-flex align-center mb-2">
                <div>
                  <v-img contain width="150" :src="pastEvent.imageUrl">
                    <template v-slot:placeholder>
                      <div class="loader"></div>
                    </template>
                  </v-img>
                </div>
                <div class="ml-2" style="flex-grow: 1">
                  <div class="mb-2">
                    <div>
                      <div class="text-h5 mb-2">
                        {{ $t("string.event_over") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="brown--text text-caption">
                {{
                  $moment(pastEvent.timestampStart).format("DD/MM/YYYY HH:mm A")
                }}
                ~
                {{
                  $moment(pastEvent.timestampEnd).format("DD/MM/YYYY HH:mm A")
                }}
              </div>
            </div>
          </div>
        </v-container>
        <SkipTut />
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import delay from "delay";
export default {
  components: {
    Sponsors: () =>
      import(
        /* webpackChunkName: "component-sponsors" */ "@/components/Sponsor/Sponsors.vue"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    tutorial: (state) => state.tutorial.data,
  }),
  props: [
    //
  ],
  data: () => ({
    subjectLang: "en",
    intraction: null,
    selectedYear: 1,
    seriesSponsors: [],
    eventSponsors: [],
    sponsorScreen: false,
    selectYearScreen: false,
    bookScreen: false,
    ticketCount: 0,
    api: {
      isLoading: true,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    PlayerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    SeriesSponsorApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    EventSponsorApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    bookApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    selected: null,
    events: [],
    nextCode: null,
    nextRoute: null,
    book: {},

    interval: null,
    countDown: 3,
  }),
  created() {
    this.api.url =
      this.$api.servers.event + "/api/v2/" + this.$_getLocale() + "/main/event";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;

      this.events = resp.data;

      if (this.events.ongoing && this.events.ongoing.length > 0) {
        this.tickets(this.events.ongoing[0].code);
      }

      // #tutExploreEvents - continue intro js ecplore Event tutorial
      if (this.tutorial.Ongoing == "tutExploreEvents") {
        if (this.events.ongoing && this.events.ongoing.length > 0) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreEvents";
          tempTut.Step = 3;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(() => {
            this.$intro().exit();
            this.$intro()
              .setOptions({
                exitOnOverlayClick: false,
                showButtons: false,
                showBullets: false,
                steps: [
                  {
                    element: document.querySelector("#StartNow"),
                    intro:
                      '<img src="' +
                      require("@/assets/tutorial/chat_1.png") +
                      '">' +
                      this.$t("tutorial.exploreEvents.dialog_3"),
                  },
                ],
              })
              .start();
            this.intraction = "pointer-events: all;";
            let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
            audio.volume = this.settings.audio.sfx * this.settings.audio.master;
            audio.play();
          }, 1000);
        } else {
          this.updateTutorial();
        }
      }
    };
  },
  mounted() {
    this.lables = [
      { id: 1, name: this.$t("string.year", { year: 1 }) },
      { id: 2, name: this.$t("string.year", { year: 2 }) },
      { id: 3, name: this.$t("string.year", { year: 3 }) },
      { id: 4, name: this.$t("string.year", { year: 4 }) },
      { id: 5, name: this.$t("string.year", { year: 5 }) },
      { id: 6, name: this.$t("string.year", { year: 6 }) },
    ];
    this.api.params = {
      countryCode: this.auth.Account.countryCode,
    };
    this.api.method = "GET";
    (async () => {
      await delay(200);
      this.$api.fetch(this.api);
    })();
  },
  methods: {
    tickets(code) {
      this.PlayerApi.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/player/load";

      this.PlayerApi.callbackReset = () => {
        this.PlayerApi.isLoading = true;
        this.PlayerApi.isError = false;
      };

      this.PlayerApi.callbackError = (e) => {
        this.PlayerApi.isLoading = false;
        this.PlayerApi.isError = true;
        this.PlayerApi.error = e;
      };
      this.PlayerApi.callbackSuccess = (resp) => {
        this.PlayerApi.isLoading = false;
        if (JSON.parse(resp.Save.data).isInitToken) {
          this.ticketCount = JSON.parse(resp.Save.data).token;
        } else {
          this.ticketCount = 20;
        }
      };
      this.PlayerApi.params = {
        eventCode: code,
        institutionId: this.auth.Institution ? this.auth.Institution.id : null
      };
      this.$api.fetch(this.PlayerApi);
    },
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    selectYear() {
      let tempAuth = this.$_.cloneDeep(this.auth);
      tempAuth.User.schoolYear = this.selectedYear;
      this.$store.commit("updateAuth", tempAuth);
      this.selectYearScreen = false;
      this.getSponsors(this.nextCode, this.nextRoute);
    },
    getSponsors(code, page) {
      this.$store.commit("updateSeries", this.selected);
      this.sponsorScreen = true;
      if (this.auth.Group.type == "institution") {
        this.SeriesSponsorApi.url =
          this.$api.servers.event +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/series/sponsor";

        this.SeriesSponsorApi.callbackReset = () => {
          this.SeriesSponsorApi.isLoading = true;
          this.SeriesSponsorApi.isError = false;
        };

        this.SeriesSponsorApi.callbackError = (e) => {
          this.SeriesSponsorApi.isLoading = false;
          this.SeriesSponsorApi.isError = true;
          this.SeriesSponsorApi.error = e;
        };
        this.SeriesSponsorApi.callbackSuccess = (resp) => {
          this.seriesSponsors = resp;
        };

        this.SeriesSponsorApi.params = {
          institutionId: this.auth.Institution.id,
          seriesId: this.selected.seriesId,
        };
        this.$api.fetch(this.SeriesSponsorApi);
      }
      this.EventSponsorApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/sponsor";

      this.EventSponsorApi.callbackReset = () => {
        this.EventSponsorApi.isLoading = true;
        this.EventSponsorApi.isError = false;
      };

      this.EventSponsorApi.callbackError = (e) => {
        this.EventSponsorApi.isLoading = false;
        this.EventSponsorApi.isError = true;
        this.EventSponsorApi.error = e;
      };
      this.EventSponsorApi.callbackSuccess = (resp) => {
        this.eventSponsors = resp;
        setTimeout(async () => {
          if (this.selected.type == "book") {
            await this.getBook(code, page);
          } else {
            this.$router.push({
              name: page,
              params: { code: code },
            });
          }
        }, 3000);
      };

      this.EventSponsorApi.params = {
        eventCode: code,
      };
      this.$api.fetch(this.EventSponsorApi);
    },
    async getBook(code, page) {
      this.bookApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/book";

      this.bookApi.callbackReset = () => {
        this.bookApi.isLoading = true;
        this.bookApi.isError = false;
        this.bookApi.error = null;
      };
      this.bookApi.callbackError = (e) => {
        this.bookApi.isLoading = false;
        this.bookApi.isError = true;
        this.bookApi.error = e;
      };
      this.bookApi.callbackSuccess = (resp) => {
        this.bookApi.isLoading = false;
        this.book = resp.Book[0];
        if (this.book.subject == "Mandarin") {
          this.subjectLang = "zh";
        } else if (this.book.subject == "Malay") {
          this.subjectLang = "ms";
        } else {
          this.subjectLang = "en";
        }
        this.sponsorScreen = false;
        this.bookScreen = true;

        this.interval = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            clearInterval(this.interval);
            this.$router.push({
              name: page,
              params: { code: code },
            });
          }
        }, 1000);
      };
      this.bookApi.params = {
        eventCode: code,
        schoolYear: this.auth.Classroom
          ? this.auth.Classroom.schoolYear
          : this.auth.User.schoolYear,
      };

      console.log(this.bookApi.params);
      await this.$api.fetch(this.bookApi);
    },
    read() {},
    goToLevels(code) {
      if (this.tutorial.Ongoing == "tutExploreEvents") {
        this.$intro().exit();
        this.updateTutorial();
      }

      if (this.auth.Player.userType == "adult") {
        this.selectYearScreen = true;
        this.nextRoute =
          this.selected.type == "book"
            ? "PageEventABCBooks"
            : "PageEventLevels";

        this.nextCode = code;
      } else {
        this.getSponsors(
          code,
          this.selected.type == "book" ? "PageEventABCBooks" : "PageEventLevels"
        );
      }

      // this.$router.push({
      //   name: "PageEventLevels",
      //   params: { code: code },
      // });
    },
    goToInfo(code) {
      if (this.auth.Player.userType == "adult") {
        this.selectYearScreen = true;
        this.nextRoute = "PageEventInfo";
        this.nextCode = code;
      } else {
        this.getSponsors(code, "PageEventInfo");
      }

      // this.$router.push({
      //   name: "PageEventInfo",
      //   params: { code: code },
      // });
    },
    goToRank(code) {
      if (this.auth.Player.userType == "adult") {
        this.selectYearScreen = true;
        this.nextRoute = "PageEventRank";
        this.nextCode = code;
      } else {
        this.getSponsors(code, "PageEventRank");
      }
      // this.$router.push({
      //   name: "PageEventRank",
      //   params: { code: code },
      // });
    },
    updateTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/complete";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        this.$store.commit("updateTutorial", resp);
      };
      if (this.tutorial.Ongoing == "tutExploreEvents") {
        this.tutorialApi.params = {
          key: "tutExploreEvents",
        };
      }
      this.$api.fetch(this.tutorialApi);
    },
    status(timestampStart, timestampEnd) {
      if (this.$moment().isAfter(timestampEnd)) {
        return "PAST";
      } else if (this.$moment().isAfter(timestampStart)) {
        return "ONGOING";
      } else if (
        this.$moment(timestampStart).diff(this.$moment(), "days") > 14
      ) {
        return "LONGUPCOMING";
      } else {
        return "UPCOMING";
      }
    },
  },
};
</script>
  
  <style scoped>
.custom-select {
  border: 2px solid #b99c7a;
  border-radius: 0;
  background-color: #fff2e2;
}
.nav {
  display: flex;
  align-items: center;
  width: 22%;
  padding: 0 5px;
  font-size: medium;
  cursor: pointer;
}

.selected-nav {
  font-size: x-large;
  display: flex;
  align-items: center;
  width: 34%;
  padding: 0 5px;
  cursor: pointer;
}

.nav-panel {
  display: flex;
  justify-content: space-around;
  padding: 0px 5px 10px 5px;
  margin-top: -20px;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 70px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /*background-color: #f5dfc5;*/
  background-color: rgba(150, 75, 0, 0.3);
}

.text-stroke-lg {
  color: #fff;
  text-shadow: #8b5629 8px 0px 0px, #8b5629 7.93758px 0.997398px 0px,
    #8b5629 7.7513px 1.97923px 0px, #8b5629 7.44406px 2.93018px 0px,
    #8b5629 7.02066px 3.8354px 0px, #8b5629 6.48771px 4.68078px 0px,
    #8b5629 5.85351px 5.45311px 0px, #8b5629 5.12797px 6.14035px 0px,
    #8b5629 4.32242px 6.73177px 0px, #8b5629 3.44941px 7.21814px 0px,
    #8b5629 2.52258px 7.59188px 0px, #8b5629 1.55638px 7.84714px 0px,
    #8b5629 0.565898px 7.97996px 0px, #8b5629 -0.433417px 7.98825px 0px,
    #8b5629 -1.42597px 7.87189px 0px, #8b5629 -2.39627px 7.63269px 0px,
    #8b5629 -3.32917px 7.27438px 0px, #8b5629 -4.21013px 6.80256px 0px,
    #8b5629 -5.02539px 6.22459px 0px, #8b5629 -5.76223px 5.54948px 0px,
    #8b5629 -6.40915px 4.78778px 0px, #8b5629 -6.95606px 3.95136px 0px,
    #8b5629 -7.39442px 3.05329px 0px, #8b5629 -7.71739px 2.10757px 0px,
    #8b5629 -7.91994px 1.12896px 0px, #8b5629 -7.9989px 0.132735px 0px,
    #8b5629 -7.95304px -0.865561px 0px, #8b5629 -7.78307px -1.85035px 0px,
    #8b5629 -7.49165px -2.80627px 0px, #8b5629 -7.08333px -3.71839px 0px,
    #8b5629 -6.56447px -4.57249px 0px, #8b5629 -5.94318px -5.35524px 0px,
    #8b5629 -5.22915px -6.05442px 0px, #8b5629 -4.43352px -6.65912px 0px,
    #8b5629 -3.5687px -7.15991px 0px, #8b5629 -2.6482px -7.54898px 0px,
    #8b5629 -1.68637px -7.82024px 0px, #8b5629 -0.698222px -7.96947px 0px,
    #8b5629 0.300817px -7.99434px 0px, #8b5629 1.29516px -7.89446px 0px,
    #8b5629 2.2693px -7.67139px 0px, #8b5629 3.20802px -7.32862px 0px,
    #8b5629 4.09668px -6.87148px 0px, #8b5629 4.92142px -6.30711px 0px,
    #8b5629 5.66936px -5.64432px 0px, #8b5629 6.32883px -4.89346px 0px,
    #8b5629 6.88954px -4.06623px 0px, #8b5629 7.34274px -3.17555px 0px,
    #8b5629 7.68136px -2.23532px 0px, #8b5629 7.90012px -1.26021px 0px,
    #8b5629 7.9956px -0.265434px 0px;
}
.text-stroke-md {
  color: #fff;
  text-shadow: #8b5629 5px 0px 0px, #8b5629 4.90033px 0.993347px 0px,
    #8b5629 4.60531px 1.94709px 0px, #8b5629 4.12668px 2.82321px 0px,
    #8b5629 3.48353px 3.58678px 0px, #8b5629 2.70151px 4.20735px 0px,
    #8b5629 1.81179px 4.6602px 0px, #8b5629 0.849836px 4.92725px 0px,
    #8b5629 -0.145998px 4.99787px 0px, #8b5629 -1.13601px 4.86924px 0px,
    #8b5629 -2.08073px 4.54649px 0px, #8b5629 -2.94251px 4.04248px 0px,
    #8b5629 -3.68697px 3.37732px 0px, #8b5629 -4.28444px 2.57751px 0px,
    #8b5629 -4.71111px 1.67494px 0px, #8b5629 -4.94996px 0.7056px 0px,
    #8b5629 -4.99147px -0.291871px 0px, #8b5629 -4.83399px -1.27771px 0px,
    #8b5629 -4.48379px -2.2126px 0px, #8b5629 -3.95484px -3.05929px 0px,
    #8b5629 -3.26822px -3.78401px 0px, #8b5629 -2.4513px -4.35788px 0px,
    #8b5629 -1.53666px -4.75801px 0px, #8b5629 -0.560763px -4.96846px 0px,
    #8b5629 0.437495px -4.98082px 0px, #8b5629 1.41831px -4.79462px 0px,
    #8b5629 2.34258px -4.41727px 0px, #8b5629 3.17346px -3.86382px 0px,
    #8b5629 3.87783px -3.15633px 0px, #8b5629 4.4276px -2.32301px 0px,
    #8b5629 4.80085px -1.39708px 0px, #8b5629 4.98271px -0.415447px 0px;
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.menu-button {
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.game-card {
  border-radius: 5px;
  border: #8b5629 5px solid;
  margin: 0px 10px 10px 10px;
  background-color: #fecb8a;
  text-align: center;
}

.game-title {
  color: #8b5629;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.container-16x9 {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.container-1x1 {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.aspect-ratio-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2; /* Placeholder color */
  animation: skeleton-loader 1s ease-in-out infinite alternate;
}

@keyframes skeleton-loader {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}

.animate-shake {
  animation: shaking 2s infinite;
}

.animate-shake:hover {
  animation: none;
}

@keyframes shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
</style>