<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div id="events">
    <v-app id="events-app">
      <v-main :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="tutorialApi.isError" :api="tutorialApi" />
        <!-- EOC -->
        <v-container class="landing-container">
          <v-row class="pa-2">
            <v-col class="d-flex justify-start align-start">
              <v-img
                :src="require('@/assets/explore/menu/family.png')"
                max-width="60"
                key="family"
                style="opacity: 0.2"
              ></v-img>
              <!-- <v-img
                :src="require('@/assets/explore/menu/school.png')"
                max-width="60"
                key="school"
                @click="friends()"
                class="d-flex justify-start align-end text-center pb-2 mx-4"
                style="opacity: 0.2"
              > -->
              <v-img
                :src="require('@/assets/explore/menu/school.png')"
                max-width="60"
                key="school"
                class="d-flex justify-start align-end text-center pb-2 mx-4"
                style="opacity: 0.2"
              >
                <v-card v-if="schoolYear" class="text-caption">{{
                  $_parseSchoolYear(schoolYear)
                }}</v-card>
              </v-img>
            </v-col>
            <v-col class="d-flex justify-end align-start">
              <Setting></Setting>
            </v-col>
          </v-row>
          <div class="top-icons px-2">
            <div>
              <div>
                <v-img
                  :src="require('@/assets/explore/menu/mailbox.png')"
                  width="70"
                  @click="mail()"
                  :class="'menu-item ' + (countMail ? 'shake' : '')"
                >
                </v-img>
              </div>
              <div>
                <v-img
                  :src="require('@/assets/explore/menu/news.png')"
                  max-width="70"
                  @click="news()"
                  class="menu-item"
                >
                </v-img>
              </div>
            </div>

            <div>
              <v-card
                class="pa-3"
                rounded="pill"
                :color="myPlanX ? 'green' : 'yellow'"
                @click="
                  myPlanX ? (myPlanDialog = true) : (subscriptionDialog = true)
                "
              >
                <v-icon :color="myPlanX ? 'white' : 'black'">
                  mdi-crown
                </v-icon>
              </v-card>
            </div>
          </div>
          <!-- EOC -->
          <div class="place-menu mt-4">
            <div class="place">
              <v-carousel hide-delimiters height="auto" :show-arrows="false">
                <v-carousel-item v-for="(item, i) in items" :key="i">
                  <div
                    @click="toggleGreeting"
                    style="cursor: pointer"
                    class="pt-12"
                  >
                    <v-img
                      contain
                      max-width="300"
                      :src="item.img"
                      class="mx-auto d-flex align-center justify-center pt-12"
                    >
                      <transition name="fade">
                        <div
                          class="d-flex align-center justify-center speacsx"
                          v-if="showGreeting"
                        >
                          <div
                            class="talk-bubble tri-right border round btm-left-in"
                          >
                            <div class="talktext">
                              <p>{{ selectedGreeting }}</p>
                            </div>
                          </div>
                        </div>
                      </transition>
                      <AbstractAvatar
                        :avatar="auth.Player.avatar"
                        :height="100"
                        :width="120"
                        class="mx-auto d-flex align-center justify-center pr-10 pb-12 animated-avatar"
                      ></AbstractAvatar>
                    </v-img>
                  </div>
                  <div class="name-board">
                    <v-card
                      class="px-2 my-2 mx-auto text-center"
                      :elevation="4"
                      style="max-width: 320px"
                      :loading="xpApi.isLoading"
                      dense
                    >
                      <v-card-text
                        @click="achievementDialog = true"
                        style="cursor: pointer"
                      >
                        <div class="black--text">
                          {{
                            auth.Student ? auth.Student.name : auth.User.name
                          }}
                        </div>
                        <div class="text-h6 brown--text">
                          {{ $t("string.avatarLevel") }} {{ xp.currentLevel }}
                        </div>
                        <v-progress-linear
                          :value="(xp.currentXp / xp.nextXpThreshold) * 100"
                          striped
                          color="amber"
                          rounded
                          class="mx-auto text-caption brown--text"
                          style="max-width: 150px"
                          height="25"
                        >
                          {{
                            xp.currentXp + " / " + xp.nextXpThreshold + " XP"
                          }}
                        </v-progress-linear>
                        <div
                          class="text-caption grey--text"
                          style="font-weight: 100 !important"
                        >
                          {{ auth.Player.mochiId }}
                          <v-btn
                            small
                            icon
                            @click="copyToClipboard(auth.Player.mochiId)"
                          >
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>

            <div class="d-flex justify-center">
              <div class="menu-button" @click="home()" id="ExploreMenu">
                <v-img
                  :src="require('@/assets/explore/menu/home.png')"
                  width="150"
                  class="menu-item"
                >
                </v-img>
              </div>
              <div class="menu-button" @click="abc()">
                <v-img
                  :src="require('@/assets/explore/menu/abc.png')"
                  width="150"
                  class="menu-item"
                >
                </v-img>
              </div>
              <div class="menu-button" @click="maths()">
                <v-img
                  :src="require('@/assets/explore/menu/maths.png')"
                  width="150"
                  contain
                  class="menu-item"
                >
                </v-img>
              </div>
              <div class="menu-button pulse" @click="serise()" id="HamochiCup">
                <v-img
                  :src="require('@/assets/explore/menu/prize.png')"
                  width="150"
                  class="menu-item"
                >
                </v-img>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
      <DebugTool v-if="settings.debug"></DebugTool>
      <AbstractChat
        v-if="tutorialChat && chatStart"
        X1="Hamochie"
        :chats="tutorialChat"
        :callback="callback"
      />
      <SkipTut />
      <newsDialog ref="ref_news" />
      <AchievementDialog
        v-if="achievementDialog"
        :callbackClose="() => (achievementDialog = false)"
        :showDialog="true"
      />
      <SubscriptionDialog
        v-if="subscriptionDialog"
        :callbackClose="() => (subscriptionDialog = false)"
        :showDialog="true"
      />
      <MyPlanDialog
        v-if="myPlanDialog"
        :callbackClose="() => (myPlanDialog = false)"
        :showDialog="true"
        :myPlan="myPlanX.Plan"
      />
      <v-snackbar v-model="snackbar" :timeout="snackTimeout" height="10" left>
        <div class="d-flex justify-space-between">
          {{ $t("action.copied") }}
          <v-icon small> mdi-check </v-icon>
        </div>
      </v-snackbar>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import delay from "delay";
import AvatarSkeleton from "@/components/skeleton/Avatar";
import SettingSkeleton from "@/components/skeleton/Setting";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    Setting: () => ({
      component: import(
        /* webpackChunkName: "component-setting" */ "@/components/Setting"
      ),
      loading: SettingSkeleton,
      delay: 200,
    }),
    DebugTool: () =>
      import(
        /* webpackChunkName: "component-debug-tool" */ "@/components/DebugTool"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    AbstractChat: () =>
      import(
        /* webpackChunkName: "component-abstract-chat" */ "@/components/tutorial/AbstractChat"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
    newsDialog: () =>
      import(
        /* webpackChunkName: "component-news-dialog" */ "@/components/news/newsDialog.vue"
      ),
    AchievementDialog: () =>
      import(
        /* webpackChunkName: "component-tutorial-dialog" */ "@/components/AchievementDialog.vue"
      ),
    SubscriptionDialog: () =>
      import(
        /* webpackChunkName: "component-subscription-dialog" */ "@/components/SubscriptionDialog"
      ),
    MyPlanDialog: () =>
      import(
        /* webpackChunkName: "my-plan-dialog" */ "@/components/MyPlanDialog"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    tutorial: (state) => state.tutorial.data,
    coin: (state) => state.coin.data,
  }),
  data: () => ({
    subscriptionDialog: false,
    myPlanDialog: false,
    snackbar: false,
    snackTimeout: 2000,
    achievementDialog: false,
    myPlanX: null,
    intraction: null,
    currentFloor: null,
    openTower: false,
    towerOpenDay: 0,
    goldenbrush: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    mailApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    planApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    countMail: 0,
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    seriesApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    pinCheckerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    xpApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    schoolYear: null,
    items: [
      {
        img: require("@/assets/explore/place.png"),
        icon: require("@/assets/explore/place.png"),
      },
      // {
      //   img: require("@/assets/explore/fishing/boat.png"),
      //   icon: require("@/assets/explore/fishing/boat.png"),
      // },
      // {
      //   img: require("@/assets/explore/kombat/dock.png"),
      //   icon: require("@/assets/explore/kombat/dock.png"),
      // },
      // {
      //   img: require("@/assets/explore/foraging/tree.png"),
      //   icon: require("@/assets/explore/mining/icon.png"),
      // },
      // {
      //   img: require("@/assets/explore/mining/cave.png"),
      //   icon: require("@/assets/explore/mining/icon.png"),
      // },
    ],
    chatStart: false,
    tutorialChat: null,
    tutExploreIntroChats: [],
    tutExploreEventsChats: [],
    tutExploreShopChats: [],
    events: [],
    greetings: [],
    selectedGreeting: "",
    showGreeting: false,
    interval: null,
    xp: {
      currentLevel: "?",
      currentXp: "?",
      nextLevel: "?",
      nextXpThreshold: "?",
    },
  }),
  created() {
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      var coins = null;
      coins = resp.filter(function (entry) {
        return entry.Item.key == "goldcoin";
      });
      var goldenbrush = null;
      goldenbrush = resp.filter(function (entry) {
        return entry.Item.key == "goldenbrush";
      });
      if (goldenbrush[0]) {
        this.goldenbrush = goldenbrush[0].Inventory.quantity;
      } else {
        this.goldenbrush = 0;
      }
      if (coins[0]) {
        this.coins = coins[0].Inventory.quantity;
      } else {
        this.coins = 0;
      }
      this.$store.commit("updateCoin", {
        Goldenbrush: { quantity: this.goldenbrush },
        Currency: { quantity: this.coins },
      });
      this.api.params = {
        countryCode: this.auth.Account.countryCode,
      };
      (async () => {
        await delay(200);
        this.$api.fetch(this.seriesApi);
      })();
    };

    //BOC:[mailApi]
    this.mailApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/mail/count";

    this.mailApi.callbackReset = () => {
      this.mailApi.isLoading = true;
      this.mailApi.isError = false;
    };

    this.mailApi.callbackError = (e) => {
      this.mailApi.isLoading = false;
      this.mailApi.isError = true;
      this.mailApi.error = e;
    };

    this.mailApi.callbackSuccess = (resp) => {
      this.mailApi.isLoading = false;
      this.countMail = resp;
    };
    //EOC
    //BOC:[pinCheckerApi]
    this.pinCheckerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/player/checkPinUpdate";

    this.pinCheckerApi.callbackReset = () => {
      this.pinCheckerApi.isLoading = true;
      this.pinCheckerApi.isError = false;
    };

    this.pinCheckerApi.callbackError = (e) => {
      this.pinCheckerApi.isLoading = false;
      this.pinCheckerApi.isError = true;
      this.pinCheckerApi.error = e;
    };

    this.pinCheckerApi.callbackSuccess = (resp) => {
      this.pinCheckerApi.isLoading = false;
      if (resp.shouldLogout) {
        let authData = this.$_.cloneDeep(this.auth);
        authData.Session = undefined;
        this.$store.commit("updateAuth", authData);
        this.$router.push({ name: "PageErrorPinChanged" });
      }
    };
    //EOC
    //BOC:[gameApi]
    this.gameApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/dailyGame/visit";

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };

    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
    };

    this.gameApi.callbackSuccess = (resp) => {
      this.gameApi.isLoading = false;
      this.currentFloor = resp.Game.currentFloor;
    };
    //EOC
    this.seriesApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/series";

    this.seriesApi.callbackReset = () => {
      this.seriesApi.isLoading = true;
      this.seriesApi.isError = false;
    };

    this.seriesApi.callbackError = (e) => {
      this.seriesApi.isLoading = false;
      this.seriesApi.isError = true;
      this.seriesApi.error = e;
    };
    this.seriesApi.callbackSuccess = (resp) => {
      this.seriesApi.isLoading = false;
      this.events = resp.Event;
      this.checkIsTowerOpen();
      this.$api.fetch(this.tutorialApi);
    };

    this.tutorialApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/tutorial/browse";
    this.tutorialApi.callbackReset = () => {
      this.tutorialApi.isLoading = true;
      this.tutorialApi.isError = false;
      this.tutorialApi.error = null;
    };
    this.tutorialApi.callbackError = (e) => {
      this.tutorialApi.isLoading = false;
      this.tutorialApi.isError = true;
      this.tutorialApi.error = e;
    };
    this.tutorialApi.callbackSuccess = (resp) => {
      this.tutorialApi.isLoading = false;
      if (resp.Tutorial) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        if (this.tutorial) {
          tempTut.Tutorial = resp.Tutorial;
        } else {
          tempTut = resp;
        }
        this.$store.commit("updateTutorial", tempTut);
      } else {
        this.$store.dispatch("resetTutorial");
      }

      // Check Tutorials
      var tutExploreIntro = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreIntro";
      });
      var tutExploreEvents = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreEvents";
      });
      var tutExploreShop = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreShop";
      });

      // #tutExploreIntro - start the tutorial
      if (tutExploreIntro.length == 0) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreIntro";
        tempTut.Step = 1;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreIntroChats),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      }
      // #tutExploreEvents - events tutorial starts
      else if (
        tutExploreEvents.length == 0 &&
        this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "ONGOING"
        )
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreEvents";
        tempTut.Step = 1;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreEventsChats),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      }
      // #tutExploreShop - shop tutorial starts
      else if (
        tutExploreShop.length == 0 &&
        this.coin.Currency.quantity &&
        this.coin.Currency.quantity > 0
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreShop";
        tempTut.Step = 1;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreShopChats),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      } else {
        this.$refs["ref_news"].fetch();
        this.$api.fetch(this.mailApi);
      }
    };
  },
  async mounted() {
    this.greetings = [
      {
        en: "Let’s play with numbers!",
        zh: "一起数一数！",
        ms: "Jom kira-kira!",
      },
      { en: "Time for a story!", zh: "故事时间！", ms: "Masa untuk cerita!" },
      { en: "Let’s draw something!", zh: "来画画吧！", ms: "Jom melukis!" },
      { en: "Adventure awaits!", zh: "冒险开始啦！", ms: "Jom berpetualang!" },
    ];

    await this.myPlan();

    this.$api.fetch(this.pinCheckerApi);

    this.tutExploreIntroChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreIntro.dialog_1"),
        sound: "sfx_chat_1",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreIntro.dialog_2"),
        sound: "sfx_chat_3",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreIntro.dialog_3"),
        sound: "sfx_chat_2",
      },
    ];

    this.tutExploreEventsChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreEvents.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];

    this.tutExploreShopChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];

    this.$api.fetch(this.api);
    // this.loadTutorial();
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
    }
    this.gameApi.params = {
      schoolYear: this.schoolYear,
    };

    this.$api.fetch(this.gameApi);
    this.loadXP();
  },
  methods: {
    async loadXP() {
      this.xpApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/xp/load";
      this.xpApi.callbackReset = () => {
        this.xpApi.isLoading = true;
        this.xpApi.isError = false;
        this.xpApi.error = null;
      };
      this.xpApi.callbackError = (e) => {
        this.xpApi.isLoading = false;
        this.xpApi.isError = true;
        this.xpApi.error = e;
        console.error(e);
      };
      this.xpApi.callbackSuccess = (resp) => {
        this.xpApi.isLoading = false;
        console.log(resp);
        this.xp = resp;
      };
      await this.$api.fetch(this.xpApi);
    },
    async toggleGreeting() {
      clearInterval(this.interval);
      this.showGreeting = false;
      await delay(200);
      this.showGreeting = true;
      this.selectedGreeting =
        this.greetings[Math.floor(Math.random() * this.greetings.length)][
          this.$_getLocale()
        ];
      this.interval = setInterval(() => {
        this.showGreeting = false;
        clearInterval(this.interval);
      }, 2000);
    },
    async myPlan() {
      this.planApi.method = "get";
      this.planApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/player/myPlan";
      this.planApi.callbackReset = () => {
        this.planApi.isLoading = true;
        this.planApi.isError = false;
        this.planApi.error = null;
      };
      this.planApi.callbackError = (e) => {
        this.planApi.isLoading = false;
        this.planApi.isError = true;
        this.planApi.error = e;
        console.error(e);
      };
      this.planApi.callbackSuccess = (resp) => {
        this.planApi.isLoading = false;
        this.myPlanX = resp.Plan;
      };
      await this.$api.fetch(this.planApi);
    },
    maths() {
      this.$router.push({
        name: "PageMath",
      });
    },
    serise() {
      this.$intro().exit();
      this.$router.push({
        name: "PageExploreSeries",
      });
    },
    home() {
      this.$intro().exit();
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
    mail() {
      this.$router.push({
        name: "PageExploreMail",
      });
    },
    friends() {
      this.$router.push({
        name: "PageExploreClassmates",
      });
    },
    news() {
      this.$refs["ref_news"].loadNews();
    },
    abc() {
      this.$router.push({
        name: "PageABCBooks",
      });
      // PageTestingExploreABC
    },
    loadTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/browse";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        if (resp.Tutorial) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          if (this.tutorial) {
            tempTut.Tutorial = resp.Tutorial;
          } else {
            tempTut = resp;
          }
          this.$store.commit("updateTutorial", tempTut);
        } else {
          this.$store.dispatch("resetTutorial");
        }
      };
      this.$api.fetch(this.tutorialApi);
    },
    status(timestampStart, timestampEnd) {
      if (this.$moment().isAfter(timestampEnd)) {
        return "PAST";
      } else if (this.$moment().isAfter(timestampStart)) {
        return "ONGOING";
      } else if (
        this.$moment(timestampStart).diff(this.$moment(), "days") > 30
      ) {
        return "LONGUPCOMING";
      } else {
        return "UPCOMING";
      }
    },
    checkIsTowerOpen() {
      // let event = this.events.find(
      //   (element) =>
      //     this.status(element.timestampStart, element.timestampEnd) == "ONGOING"
      // );
      const eventStartDate = this.$moment("2023-12-18 07:00");
      const currentDate = this.$moment();
      const daysDifference = currentDate.diff(eventStartDate, "days");

      if (daysDifference >= 0) {
        this.openTower = true;
      } else {
        this.openTower = false;
        this.towerOpenDay = 7 - daysDifference;
      }

      // else{
      //   this.openTower = true;
      // }
      if (this.settings.debug) {
        this.openTower = true;
      }
    },
    callback() {
      this.chatStart = false;
      // #tutExploreIntro - after the intrduction finish, update tutorial
      if (this.tutorial.Ongoing == "tutExploreIntro") {
        this.tutorialApi.url =
          this.$api.servers.game +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/tutorial/complete";
        this.tutorialApi.callbackReset = () => {
          this.tutorialApi.isLoading = true;
          this.tutorialApi.isError = false;
          this.tutorialApi.error = null;
        };
        this.tutorialApi.callbackError = (e) => {
          this.tutorialApi.isLoading = false;
          this.tutorialApi.isError = true;
          this.tutorialApi.error = e;
        };
        this.tutorialApi.callbackSuccess = (resp) => {
          this.tutorialApi.isLoading = false;
          this.$store.commit("updateTutorial", resp);

          // #tutExploreEvents - after update intro tutorial triger event tutorial
          var tutExploreEvents = this.tutorial.Tutorial.filter(function (
            entry
          ) {
            return entry.key == "tutExploreEvents";
          });
          if (tutExploreEvents.length == 0) {
            if (
              this.events.find(
                (element) =>
                  this.status(element.timestampStart, element.timestampEnd) ==
                  "ONGOING"
              )
            ) {
              let tempTut = this.$_.cloneDeep(this.tutorial);
              tempTut.Ongoing = "tutExploreEvents";
              tempTut.Step = 1;
              this.$store.commit("updateTutorial", tempTut);

              this.intraction = "pointer-events: none;";
              setTimeout(
                () => (
                  (this.tutorialChat = this.tutExploreEventsChats),
                  (this.chatStart = true),
                  (this.intraction = "pointer-events:all")
                ),
                800
              );
            }
          }
        };
        this.tutorialApi.params = {
          key: "tutExploreIntro",
        };
        this.$api.fetch(this.tutorialApi);
      }

      // #tutExploreEvents - after the event tutorial intrduction finish it will triger introjs to gide user to click hamochi cup
      else if (
        this.tutorial.Ongoing == "tutExploreEvents" &&
        this.tutorial.Step == 1
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreEvents";
        tempTut.Step = 2;
        this.$store.commit("updateTutorial", tempTut);

        // check the route if user in town page it will triger the introjs
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#HamochiCup"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreEvents.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      }

      // #tutExploreShop - after the shop tutorial intrduction finish it will triger introjs to gide user to click menu button
      else if (
        this.tutorial.Ongoing == "tutExploreShop" &&
        this.tutorial.Step == 1
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreShop";
        tempTut.Step = 2;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#ExploreMenu"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreShop.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },
  },
};
</script>
  
<style scoped>
.place {
  position: relative;
}
.name-board {
  margin-top: -120px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.pulse:hover {
  animation: none;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
  margin: 10px;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.shake {
  -webkit-animation: shake 2s infinite ease-in-out;
  -moz-animation: shake 2s infinite ease-in-out;
  -ms-animation: shake 2s infinite ease-in-out;
  -o-animation: shake 2s infinite ease-in-out;
  animation: shake 2s infinite ease-in-out;
}
@keyframes shake {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

#events {
  background-color: rgba(255, 228, 181, 0.3);
  /*background-color: #c7e5e9;*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#events-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.energy-panel {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(30% - 10px) !important;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  padding: 0 5px;
  justify-content: space-between;
}

.nav {
  width: 16.66%;
  padding: 0 2px;
}

.nav-panel {
  display: flex;
  padding: 5px 10px;
}

.nav-text {
  font-size: small;
  text-align: center;
  line-height: 12px;
  margin-top: -10px;
  color: #fff;
  position: relative;
  text-shadow: rgb(75, 75, 75) 3px 0px 0px,
    rgb(75, 75, 75) 2.83487px 0.981584px 0px,
    rgb(75, 75, 75) 2.35766px 1.85511px 0px,
    rgb(75, 75, 75) 1.62091px 2.52441px 0px,
    rgb(75, 75, 75) 0.705713px 2.91581px 0px,
    rgb(75, 75, 75) -0.287171px 2.98622px 0px,
    rgb(75, 75, 75) -1.24844px 2.72789px 0px,
    rgb(75, 75, 75) -2.07227px 2.16926px 0px,
    rgb(75, 75, 75) -2.66798px 1.37182px 0px,
    rgb(75, 75, 75) -2.96998px 0.42336px 0px,
    rgb(75, 75, 75) -2.94502px -0.571704px 0px,
    rgb(75, 75, 75) -2.59586px -1.50383px 0px,
    rgb(75, 75, 75) -1.96093px -2.27041px 0px,
    rgb(75, 75, 75) -1.11013px -2.78704px 0px,
    rgb(75, 75, 75) -0.137119px -2.99686px 0px,
    rgb(75, 75, 75) 0.850987px -2.87677px 0px,
    rgb(75, 75, 75) 1.74541px -2.43999px 0px,
    rgb(75, 75, 75) 2.44769px -1.73459px 0px,
    rgb(75, 75, 75) 2.88051px -0.838247px 0px;
}

.menu-button {
  cursor: pointer;
  width: 20%;
  display: flex;
  justify-content: center;
}

.floor-text {
  padding-top: 60px;
  text-align: center;
  color: black;
  /* font-size: 20px;
  -webkit-text-stroke: 0.5px white !important; */
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}
.disabled-image {
  filter: grayscale(100%) !important;
}

.animated-avatar {
  animation: idle 2s infinite alternate ease-in-out;
  transform-origin: center calc(100% - 5px);
}

@keyframes idle {
  0% {
    transform: scale(1, 1); /* Original size */
  }
  100% {
    transform: scale(1, 1.05); /* Slightly stretched */
  }
}

.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: #fffcd8;
}
.border {
  border: 4px solid #000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.round {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #000000 transparent transparent #000000;
}
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 34px;
  right: auto;
  top: auto;
  bottom: -30px;
  border: 18px solid;
  border-color: #fffcd8 transparent transparent #fffcd8;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  /* color: #fff; */
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.speacsx {
  position: absolute;
  bottom: calc(50% + 30px);
  left: calc(50% - 100px);
}

.top-icons {
  position: absolute;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.landing-container {
  max-width: 480px;
  padding: 0;
  height: 100%;
  position: relative;
  min-height: 550px !important;
}

.place-menu {
  /* height: calc(100% - 132px); */
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  flex-direction: column;
  justify-content: end;
}
</style>